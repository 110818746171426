var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('nav',{staticClass:"navbar-nav"},[_c('nav',{attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{name: 'projects-index'}}},[_vm._v("Projects")])],1),(_vm.project)?_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{
							name: 'projects-editor-uuid-index',
							params: {
								uuid: _vm.project.Uuid
							}
						}},domProps:{"textContent":_vm._s(`${_vm.project.Code} - ${_vm.project.Name}`)}})],1):_vm._e(),(_vm.config)?_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{
							name: 'projects-editor-uuid-cuuid-config-index',
							params: {
								uuid: _vm.$route.params.uuid,
								cuuid: _vm.$route.params.cuuid
							}
						}}},[_c('span',{staticClass:"font-weight-bold",domProps:{"textContent":_vm._s(_vm.config.Name)}}),_c('span',{staticClass:"text-small ml-2",domProps:{"textContent":_vm._s(`v. ${_vm.config.Version}`)}})])],1):_vm._e(),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v(" Settings ")])])])]),(_vm.project && _vm.config)?_c('project-navbar',{attrs:{"project":_vm.project,"project-config":_vm.config}}):_vm._e(),_c('configurator-settings-form',{staticClass:"mt-4",attrs:{"synced":_vm.saved,"disabled":!_vm.canEdit},on:{"save":_vm.save},model:{value:(_vm.config),callback:function ($$v) {_vm.config=$$v},expression:"config"}}),_c('hr'),(_vm.config && _vm.project && _vm.canEdit)?_c('button',{staticClass:"btn btn-danger mb-5",on:{"click":function($event){$event.preventDefault();return _vm.deleteConfig.apply(null, arguments)}}},[_vm._v("Delete "+_vm._s(_vm.project.Name)+" / "+_vm._s(_vm.config.Name)+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }