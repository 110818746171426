<template>
	<form @submit.prevent="$emit('save')" v-if="model">
		<div>
			<div class="row">
				<div class="col">
					<div class="form-group">
						<label for="name" class="font-weight-bold">Math model name</label>
						<input :disabled="disabled" autocomplete="off" id="name" v-model="model.Name" placeholder="Name"
									 type="text"
									 required="required"
									 class="form-control">
					</div>
				</div>
				<div class="col">
					<div class="form-group">
						<label for="version" class="font-weight-bold">Version</label>
						<input :disabled="disabled" autocomplete="off" id="version" v-model="model.Version" placeholder="Version"
									 type="text"
									 required="required"
									 class="form-control">
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col">
					<div class="form-group">
						<label for="status" class="font-weight-bold">Status</label>

						<div v-for="status in statuses" class="form-check">
							<input class="form-check-input" :id="status.code" type="radio" :value="status.code" v-model="model.Status">
							<label class="form-check-label mb-2 d-block" :for="status.code">
								<div class="font-weight-bold rounded px-2 text-uppercase" :class="`vsm--badge vsm--badge_default vsm--badge_status-${status.code}`">{{status.code}}</div>
								<div class="pl-2">- {{status.description}}</div>
							</label>
							<hr class="mb-3 mt-0">
						</div>

						<select id="status" class="form-control text-capitalize" v-model="model.Status" :disabled="disabled">
							<option v-for="status in statuses">{{ status }}</option>
						</select>
					</div>
				</div>
				<!--
				<div class="col-lg-6" v-if="synced && model.Status === 'released'">
					<div class="form-group">
						<div class="d-flex">
							<div class="flex-grow-1">
								<label class="font-weight-bold">Unlock status</label>
								<div class="py-2">Restore this configuration to Testing status</div>
							</div>
							<div>
								<label for="unlock-status" class="font-weight-bold text-white">.</label>
								<div>
									<button id="unlock-status" class="btn btn-danger" type="submit" @click.prevent="restore">Confirm
										restore
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				-->
			</div>

			<div class="row">
				<div class="col">
					<div class="form-group">
						<label for="version" class="font-weight-bold">Notes</label>

						<ckeditor class="editor w-100" :config="editorConfig" :editor="editor" v-model="model.Notes"
											:disabled="disabled"></ckeditor>
					</div>
				</div>
			</div>

			<div class="form-group">
				<button class="btn btn-success" type="submit" :disabled="disabled">Save</button>
			</div>

		</div>
	</form>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
	name: 'configurator-settings-form',
	props: {
		value: {},
		disabled: {
			type: Boolean,
			default: false
		},
		synced: {
			type: Boolean,
			default: false
		}
	},
	data: () => ({
		editor: ClassicEditor,
		editorConfig: {
			toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'insertTable'],
		},
		password: ''
	}),
	methods: {
		restore() {
			this.model.Status = 'testing'
			this.$emit('save')
		}
	},
	computed: {
		//statuses: () => ["created", "development", "testing", "ready", "released"],
		statuses: () => [
			{
				code: 'created',
				description: 'It is the first status of a project after creation, used only to insert configuration placeholders.'
			},
			{
				code: 'development',
				description: 'Indicates that the math department is working on it.'
			},
			{
				code: 'testing',
				description: 'During this phase, the slot is tested, producing the various reports and logs.'
			},
			{
				code: 'ready',
				description: 'Indicates that the math is tested and working, almost ready for release.'
			},
			{
				code: 'released',
				description: 'This state indicates that the math is ready, and any placeholders or test variables have been removed. After this state it will not be possible to further modify the mathematics, if not returning it to a previous state.'
			}
		],
		model: {
			get() {
				return this.value
			},
			set(val) {
				this.$emit("input", val)
			}
		}
	}
}
</script>
