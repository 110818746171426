<template>
	<div>

		<nav class="navbar-nav">
			<nav aria-label="breadcrumb">
				<ol class="breadcrumb">
					<li class="breadcrumb-item">
						<router-link :to="{name: 'projects-index'}">Projects</router-link>
					</li>

					<li class="breadcrumb-item" v-if="project">
						<router-link :to="{
								name: 'projects-editor-uuid-index',
								params: {
									uuid: project.Uuid
								}
							}" v-text="`${project.Code} - ${project.Name}`"></router-link>
					</li>

					<li class="breadcrumb-item" v-if="config">
						<router-link :to="{
								name: 'projects-editor-uuid-cuuid-config-index',
								params: {
									uuid: $route.params.uuid,
									cuuid: $route.params.cuuid
								}
							}">
							<span class="font-weight-bold" v-text="config.Name"></span>
							<span class="text-small ml-2" v-text="`v. ${config.Version}`"></span>
						</router-link>
					</li>

					<li class="breadcrumb-item active">
						Settings
					</li>

				</ol>
			</nav>

		</nav>

		<project-navbar v-if="project && config" :project="project" :project-config="config"></project-navbar>

		<configurator-settings-form :synced="saved" :disabled="!canEdit" class="mt-4" v-model="config" @save="save"/>

		<hr>

		<button v-if="config && project && canEdit" class="btn btn-danger mb-5" @click.prevent="deleteConfig">Delete
			{{ project.Name }} / {{
				config.Name
			}}
		</button>

	</div>
</template>

<script>
import api from '@/api'
import ConfiguratorSettingsForm from "@/components/projects/ConfiguratorSettingsForm";
import ProjectNavbar from "@/components/projects/project-navbar";

export default {
	name: "settings",
	components: {ProjectNavbar, ConfiguratorSettingsForm},
	data: () => ({
		config: null,
		project: null,
		saving: false,
		saved: true
	}),
	async mounted() {
		this.project = await api.Projects.getProject(this.$route.params.uuid)
		this.config = await api.Projects.getProjectConfig(this.$route.params.cuuid)
	},
	computed: {
		canEdit() {
			return this.canWrite('projects_config')
		}
	},
	methods: {
		async save() {
			this.saving = true
			console.log(this.config)
			await api.Configurations.update(this.config.Id, {
				Name: this.config.Name,
				Version: this.config.Version,
				Status: this.config.Status,
				Notes: this.config.Notes
			})
			this.saved = true
			this.$toast.open('Configuration saved')
			this.saving = false
			this.$store.dispatch('Projects/load')
		},
		async deleteConfig() {
			const deleteConfirmed = confirm(`Are you sure you want to delete ${this.project.Name} / ${this.config.Name}`)

			if (deleteConfirmed) {
				await api.Configurations.delete(this.config.Id)
				this.$store.dispatch('Projects/load')
				this.$router.replace({
					name: "projects-editor-uuid-index",
					params: {
						uuid: this.project.Uuid
					}
				});
			}
		}
	}
}
</script>

